:root {
  --main-bg-light: #b9c4c9;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Roboto";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--main-bg-light) !important;
}

input {
  outline: none;
  border: none;
  background: transparent;
}

.create {
  max-width: 1300px;

  height: 470px;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &_title {
    margin: 0 auto;
    font-weight: 100;
    text-shadow: 0 1px 2px #000;
  }
}
