@import url("https://fonts.googleapis.com/css2?family=Lilita+One&family=Roboto:wght@100;300;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap");

:root {
  --neon-shadow-green: #0fa;
  --neon-shadow-white: #fff;
  --neon-text-color: #fff;
  --neon-text-shadow: 0 0 7px var(--neon-shadow-white),
    0 0 10px var(--neon-shadow-white), 0 0 21px var(--neon-shadow-white),
    0 0 42px var(--neon-shadow-green), 0 0 82px var(--neon-shadow-green),
    0 0 92px var(--neon-shadow-green), 0 0 102px var(--neon-shadow-green),
    0 0 151px var(--neon-shadow-green);
  --main-light: var(rgb(185, 196, 201));
  --second-light: var(rgb(82, 149, 139));
  --main-dark: var(rgb(0, 77, 71));
  --second-dark: var(rgb(18, 130, 119));
}

.header {
  font-size: 68px;
  line-height: 8px;
  height: 30vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-top: 280px;
  &_background {
    width: 100%;
    height: 275px;
  }
}

.neon_text {
  letter-spacing: 3px;
  color: var(--neon-text-color);
  text-shadow: var(--neon-text-shadow);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(185, 196, 201, 0.85) 80%,
    rgba(185, 196, 201, 0.95) 90%,
    rgb(185, 196, 201) 100%
  );
  transform: translateY(-285px);
  width: 100%;
  height: 267px;
  text-align: center;
  animation: neon_light 0.7s linear;
  animation-delay: 0.5s;
  margin-block: 20px;
}

.neon_title,
.neon_discr {
  text-align: center;

  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-style: normal;
}

@keyframes neon_light {
  0% {
    text-shadow: none;
  }
  40% {
    text-shadow: var(--neon-text-shadow);
  }
  50% {
    text-shadow: none;
  }
  70% {
    text-shadow: var(--neon-text-shadow);
  }
  80% {
    text-shadow: none;
  }
  90% {
    text-shadow: var(--neon-text-shadow);
  }
  95% {
    text-shadow: none;
  }
  100% {
    text-shadow: var(--neon-text-shadow);
  }
}

@media (max-width: 890px) {
  .neon_text {
    transform: translateY(-217px);
  }
  .header {
    font-size: 48px;
    line-height: 6px;
    padding-top: 65px;
  }
}
@media (max-width: 810px) {
  .header {
    margin-bottom: 0;
  }
}

@media (max-width: 615px) {
  .neon_text {
    transform: translateY(-185px);
  }
  .header {
    font-size: 42px;
    line-height: 4px;
    margin-bottom: -30px;
    padding-top: 60px;
  }

  .neon_title {
    transform: translateY(-30px);
  }

  .neon_discr {
    transform: translateY(-30px);
  }
}

@media (max-width: 420px) {
  .neon_title {
    transform: translateY(-10px);
  }

  .neon_discr {
    transform: translateY(-10px);
  }
  .header {
    padding-top: 90px;
  }
}

@media (max-width: 370px) {
  .header {
    margin-bottom: -75px;
    margin-top: 67px;
  }
}

.title {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-style: normal;
  text-shadow: 0 1px 2px #000;
  &__separator {
    margin-inline: 10px;
    font-weight: 400;
  }
  &__wrapper {
    display: flex;
    justify-content: space-around;
    background-color: var(--main-bg-light) !important;
    align-items: center;
    max-width: 1300px;
    margin: 20px auto;
    padding: 0 20px;
  }
  &__button {
    font-family: "Roboto", sans-serif;
    font-size: 22px;
    width: 192px;
    height: 35px;
    border: 1px solid rgb(0, 0, 0);
    color: #b9c4c9;

    background: linear-gradient(
      313deg,
      rgb(0, 77, 71) 0%,
      rgb(82, 149, 139) 100%
    );

    border-radius: 5px;

    cursor: pointer;
    letter-spacing: 2px;
    transition: text-shadow 0.3s ease;
    box-shadow: 0 1px 2px rgb(0, 0, 0);

    text-shadow: 1px 1px 1px rgb(0, 77, 71);
    transition: all 0.2s ease-out;
    &:hover {
      box-shadow: 0 2px 3px rgb(0, 0, 0);
    }
  }
}

@media (max-width: 430px) {
  .title {
    &__wrapper {
      justify-content: space-between;
      padding: 0 5px;
    }
    &__button {
      height: 40px;
      width: 140px;
    }
  }
}
